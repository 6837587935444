/** @jsx jsx */

//import React from 'react'
//import Link from '../components/Link'
import MySEO from '../components/seo'
import {Container,Box,jsx,Grid,Heading,Text} from 'theme-ui'
import Layout from '../components/layout'
import {StaticImage} from 'gatsby-plugin-image'






export default function Verluste({data}){
  return(
    <Layout>
    
<MySEO title="Verluste:Projekte von K.O.M" description="Projekte vom K.O.M" />
<Container sx={{my:3,color:`text`}} >

{/* 
<Box sx={{m:0}}><h1 sx={{fontSize:[4,5],fontWeight: 300, letterSpacing:"0.09rem"}}>Unsere Projekte</h1></Box>
<hr/> */}

   <Heading sx={{fontWeight:400,mt:3,mb:2,fontSize:[2,3,4]}}>Ausstellungsparcours: Verluste.<br/> Jüdisches Leben im Mittelrheintal.</Heading>



</Container>
<Grid  
 columns={[1,1,2]}
 gap={4}
>



<Box sx={{color:`text`}}>

<Text py="1">
Pandemiebedingt wurde der <span style={{fontWeight:500}}> Ausstellungsparcours „Verluste. Jüdisches Leben im Mittelrheintal"</span>, der zeitgleich mit dem <span style={{fontWeight:500}}> Theaterfest "An den Ufern der Poesie" des Theater Willy Praml </span>  im Kontext des Jubiläums "1700 Jahre jüdisches Leben in Deutschland" für 2021 geplant worden war, auf 2022 verschoben.
</Text> 
<Box>
<a rel="noopener noreferrer" target="_blank" href="/pdf/verluste.pdf" sx={{color:'text',paddingTop:2,lineHeight:'1.1',fontWeight:300,fontStyle:'italic', fontSize:2,textDecoration:'none'}}>&rarr; Download Programm 'Verluste' </a> 
</Box>

<Box>
<a rel="noopener noreferrer" target="_blank" href="/pdf/theaterfestival.pdf" sx={{color:'text',fontSize:2, paddingTop:2,lineHeight:'1.1',fontWeight:300,fontStyle:'italic',textDecoration:'none'}}> &rarr; Download Programm 'Theaterfestival'   </a> 
</Box>

  <Box py="3">
    <Text py="1">
      Ausstellungsparcours sowie das 
       Theaterfestival "An den Ufern der Poesie"
       werden nun <span style={{fontWeight:500}}>im Juni/Juli 2022 stattfinden.</span>
    </Text>
    <Text py="1">Wir als Veranstalter freuen uns darüber sehr!</Text>
  <Text py="1">
    Das Programm zum Theaterfest wurde etwas abgewandelt und steht demnächst auf dieser Website zum Download bereit. Manche Akteure können in diesem Jahr leider nicht mitwirken, andere spannende Partner konnten gewonnen werden.
  </Text>
  </Box>

<Text py="1">
Als 'Brücke' und Jubiläums-Würdigung veranstalteten wir 2021 ein <span style={{fontWeight:500}}> "Stolperstein"-Konzert im Klostergarten Oberwesel</span> mit einem Duo der Klezmerband Colalaila sowie einer  Lesung von Leona Riemann, die ihre aus historischen Quellen recherchierte Kurzgeschichte über den  Hunsrücker Komponisten und Musiker Samuel Baum vortrug. 

</Text>
<Box py="4">
<a sx={{color:'text',textDecoration:'none'}} href="pdf/Flyer_Colalaila.pdf" rel="noopener noreferrer" target="_blank">&rarr; Flyer Colallaila zum Download</a>
</Box>
<Text py="1">
Außerdem präsentierten wir im Herbst 2021 die <span style={{fontWeight:500}}>Fotoausstellung "Zusammenhalt in Vielfalt. Jüdischer Alltag in Deutschland"</span> mit zehn prämierten Einreichungen zum Fotowettbewerb der <span style={{fontWeight:500}}>Initiative Kulturelle Integration. </span>
</Text>
<Text py="1">
Im Rahmen der Vernissage fand ein Konzert mit dem <span style={{fontWeight:500}}>Duo Ségotal</span> statt. Ségolène de Beaufond aus Versailles und Tal Botvinik aus Jerusalem, beide in Köln lebend, spielten Stücke u.a. von Bach und Astor Piazolla sowie traditionelle jüdische Lieder. <span style={{fontWeight:500}}>Dieter Burgard, Beauftragter der Ministerpräsidentin für jüdisches Leben und Antisemitismusfragen der Staatskanzlei Rheinland-Pfalz</span>, beehrte uns mit seiner Anwesenheit und hielt eine berührende Rede.
 
</Text>


<Text py="1">
Im diesem Jahr sind nun analoge und virtuelle Ausstellungen und Installationen in Bacharach und Oberwesel mit Fakten zur jüdischen Geschichte am Mittelrhein geplant.
<span style={{fontWeight:500}}> Die Galerie Markt1</span> wird multimedial Zeitzeugen des 20. Jahrhunderts zu Wort kommen lassen und Impressionen und Geschichten zu den Verlusten Bacharachs erzählen. Relikte des "Roten Fenster" des <span style={{fontWeight:500}}>Künstlers Karl-Martin Hartmann</span>, das von 2007 bis 2010 in der Wernerkapelle Bacharach installiert war, werden in Schaufenstern Bacharachs installiert sein. Weitere Veranstaltungen und Ausstellungen – insbesondere zum <span style={{fontWeight:500}}>antisemitischen Wernerkult der Region</span> sowie den verlegten und noch zu verlegenden Stolpersteinen – sind in Oberwesel und Bacharach geplant. 

 </Text>


<Grid columns={[1,2,1,1]}>
<Box display="flex" flex-direction="column" mb="5" pt="0">
    <StaticImage src="../images/projekte/daemmer.jpeg" alt =""/>
    <p sx={{color:'text',lineHeight:'1.1', pl:3, pt:2 ,fontWeight:300,fontStyle:'italic', fontSize:0}}>Gunter Demnig: Verlegung von Stolpersteinen in Bacharach</p>
    </Box>
 
    <Box display="flex" flex-direction="column" mt="1">
    <StaticImage src="../images/projekte/animation.jpeg" alt =""/>
    <p sx={{color:'text',lineHeight:'1.1', pl:3, pt:0 ,fontWeight:300,fontStyle:'italic', fontSize:0}}>Beispiel typographischer Animationen für Projektion, Bildschirme &amp; Internet</p>
    </Box>
 </Grid>
</Box>

<Box>
<Box display="flex" flex-direction="column" my="0" pb="5">
  <Box height="200" mb="2">
  <a sx={{color:'black',textDecoration:'none',fontSize:2}} rel="noopener noreferrer" target="_blank" href="https://juedisches-leben-am-mittelrhein.de">&rarr; https:/juedisches-leben-am-mittelrhein.de</a>
  </Box>
    <StaticImage src="../images/programm-verluste.jpg" alt =""/>
     <Box py="2">
      <a rel="noopener noreferrer" target="_blank" href="/pdf/verluste.pdf" sx={{color:'text',paddingTop:2,lineHeight:'1.1',fontWeight:300,fontStyle:'italic', textDecoration:'none',fontSize:1}}>&rarr; Download Programm </a> 
      </Box>
  
    </Box>
    <Grid columns={[1,2,1,1]}>
    <Box display="flex" flex-direction="column" my="3">
      
    
      <StaticImage src="../images/projekte/rotesfenster.jpeg" alt =""/>
      <p sx={{color:'text',lineHeight:'1.1', pt:2 ,pb:2,fontWeight:300,fontStyle:'italic',fontSize:0}}>
        Retrospektive des 'Roten Fensters' von  Karl-Martin Hartmann
        </p>
    </Box>
    <Box pt="0" my="0">

 <Box display="flex" flex-direction="column">
<StaticImage src="../images/projekte/werner.jpeg" alt =""/>
</Box> 
  <p sx={{color:'text',lineHeight:'1.1', width:'100%', pt:2,pb:3,fontWeight:300,fontStyle:'italic', fontSize:0}}>St. Werner Skulptur in Oberwesel</p>


</Box>
</Grid>

    </Box>
    
 </Grid>

 <Grid columns={[1,2]}>
    <Box  display="flex" flex-direction="column" mt="3" mb="0">
    <StaticImage src="../images/projekte/jleben1.jpeg" alt ="" layout="constrained" />
      <p sx={{width:[300,300,450],color:'text',lineHeight:'1.1', pt:2 ,fontWeight:300,fontStyle:'italic',fontSize:0}}>Fotodokumente zum 'Jüdischen Leben im Hunsrück' vom Museum Simmern </p>
    </Box>

    <Box display="flex" flex-direction="column" mb="0">
    <StaticImage src="../images/projekte/jleben2.jpeg" alt =""/>
      <p sx={{width:[300,300,500],color:'text',lineHeight:'1.1', pt:2 ,fontWeight:300,fontStyle:'italic', fontSize:0}}>Fotodokumente zum 'Jüdischen Leben im Hunsrück' vom Museum Simmer</p>
    </Box>
    </Grid>

</Layout>

  )
}


